<template>
  <v-container></v-container>
</template>
<script>
export default {
  name: "DocsR",
  mounted() {
    // console.log(this.$route.params);
    if (this.$route.params.docType) {
      this.$router.push({
        name: this.$route.params.to,
        params: this.$route.params,
      });
    } else {
      history.back();
    }
  },
};
</script>
